export const Posts = {

  pages: 1,
  current_page: 1,
  items_number: 6,
  items: [],

  initialize: function() {
    if ($("#posts").length) {
      Posts.makePages()
      Posts.bindPagination()
      Posts.bindCategories()
    }
  },

  showPage: function (page) {
    Posts.items.hide().slice((page - 1) * Posts.items_number, page * Posts.items_number).show();
    Posts.current_page = page;
    $("#pagination #pages span").removeClass("active")
    $("#pagination #pages span:contains("+Posts.current_page+")").addClass('active')
  },

  makePages: function(){
    Posts.items = $(".landing-page-list .landing-page:visible");
    Posts.pages = Math.ceil(Posts.items.length / Posts.items_number);
    $("#pagination #pages").empty();
    for(var p=1;p<=Posts.pages;p++){
      $("#pagination #pages").append($('<span>'+p+'</span>'));
    }
    Posts.showPage(1);
  },

  bindPagination: function() {
    $("#pagination").on('click', 'span', function(){
      $("#pagination .next, #pagination .prev").attr("disabled", false)
      var action = $(this).text();
      if ($(this).hasClass("prev")){
        Posts.current_page--;
      } else if ($(this).hasClass("next")){
        Posts.current_page++;
      } else if ( +$(this).text() > 0 ) {
        Posts.current_page = +$(this).text()
      }

      if (Posts.current_page <= 1) {
        Posts.current_page = 1;
        $("#pagination .prev").attr("disabled", true)
      } else if (Posts.current_page >= Posts.pages) {
        Posts.current_page = Posts.pages;
        $("#pagination .next").attr("disabled", true)
      }
      $(window).scrollTop($(".landing-page-list").offset().top - 120);
      Posts.showPage(Posts.current_page);
    });
  },

  bindCategories: function() {
    $('.blog-categories .category').click(function() {
      $('.blog-categories .category').removeClass("active")
      $(this).addClass("active")
      $(".landing-page-list .landing-page").show()
      if ($(this).data("category")) {
        $(".landing-page-list .landing-page").not("[data-categories*='"+$(this).data("category")+"']").hide()
      }
      Posts.makePages();
    });
    $('.blog-categories .category.active').click()
  }

}
