export const Ailments = {

  initialize: function() {
    if ($("#ailments").length) {
      $(".landing-page-list .landing-page").show();
      Ailments.bindBtnHover();
      Ailments.bindBtnClick();
    }
  },

  bindBtnHover: function() {
    $('.complaintsForm .complaintsForm-steps .btn-orange').hover(function(){     
      console.log($(this).data("section"))
      $('.complaintsForm .complaintsForm-image span[data-section='+$(this).data("section")+']').addClass('hover');    
    },     
    function(){    
      $('.complaintsForm .complaintsForm-image span').removeClass('hover');    
    });
  },

  bindBtnClick: function() {
    $('.complaintsForm .complaintsForm-steps .btn-orange, .complaintsForm .complaintsForm-image span').click(function() {
      $('.complaintsForm .complaintsForm-steps .btn-orange').removeClass("active");
      $('.complaintsForm .complaintsForm-image span').removeClass("active");
      $('.complaintsForm .complaintsForm-steps .btn-orange[data-section='+$(this).data('section')+']').addClass("active");
      $('.complaintsForm .complaintsForm-image span[data-section='+$(this).data('section')+']').addClass("active");
      $("#posts .landing-page").hide();
      $("#posts .landing-page[data-section*="+$(this).data("section")+"]").show();
    });
  }
}
