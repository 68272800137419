export const Opinions = {
  initialize: function() {
    if ($("section#new-opinion").length) {
      Opinions.bindFileInputs();
    }
  },

  bindFileInputs: function(){
    $("button#add-file").click( function() {
      $('#opinion_image').trigger('click')
    });
    $('#opinion_image').change(function(){
      var file = this.files[0];
      $('#opinion-filename').text(': ' + this.files[0].name)
      if (file.size > (1024 * 1024 * 10)) {
        $(this).parent().find(".invalid-feedback").show();
        $(this).parents("form").find("[type=submit]").attr("disabled", "disabled");
        $(this).parents("form").find("[type=submit]").addClass("disabled")
      } else {
        $(this).parent().find(".invalid-feedback").hide();
        $(this).parents("form").find("[type=submit]").removeAttr("disabled");
        $(this).parents("form").find("[type=submit]").removeClass("disabled")
      }

    })

  }
}
