// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

global.$ = jQuery;                                                               
global.jQuery = jQuery;
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("bootstrap");
require('fslightbox');
import SwiperCore, { Navigation,Thumbs } from 'swiper/core';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Navigation,Thumbs]);

import { Posts } from 'packs/posts';
import { Ailments } from 'packs/ailments';
import { Opinions } from 'packs/opinions';

$(document).on("turbolinks:load", function(){
  refreshFsLightbox();
  checkHeader();
  Posts.initialize();
  Ailments.initialize();
  Opinions.initialize();

  $(".alert-box .close").click(function(){ $(this).parent().remove(); })

  new SwiperCore('.timeline-container', {
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      661: {
        slidesPerView: 2,
      },
      1025: {
        slidesPerView: 3,
      }
    }
  })

  new SwiperCore('.swiper-init', {
    slidesPerView: 1,
    spaceBetween: 16,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      449: {
        slidesPerView: 2,
      },
      990: {
        slidesPerView: 3,
      }
    }
  })

  var swiper = new SwiperCore(".meet-us-swiper-pagination", {
    loop: true,
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    breakpoints: {
      990: {
        slidesPerView: 5,
      }
    }
  });

  new SwiperCore(".meet-us-swiper", {
    loop: true,
    spaceBetween: 10,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    thumbs: {
      swiper: swiper,
    },
  });

  $(window).click(function() {
    $(".languages").removeClass("active")
  });

  $('.current-language').click(function(event){
    event.stopPropagation();
    $(".languages").toggleClass("active")
  });
  
  if ($("section.imagePoints.section").length) {
    $("section.imagePoints [data-image-point]").click(function() {
      $(".imagePoints-01, .imagePoints-02, .imagePoints-03").removeClass("active")
      $(".imagePoints-0" + $(this).data("imagePoint")).addClass("active")
      $(".simplebar-content-wrapper")[0].scrollTop = 0
    });
  }

  if ($("#meet-us").length) {
    $(".references .box").hide()
    $(".references .box:not(:visible)").slice(0,8).show()
    $(".references .show-more").click(function() {
      $(".references .box:not(:visible)").slice(0,4).show()
    })
  }
  if ($("#form-step-0").length) {
    $(window).bind('scroll resize', function(){
      checkHeader();
      if ($(window).scrollTop() > 420) {
        $("section.cta-box").addClass("fixed");
      } else {
        $("section.cta-box").removeClass("fixed");
      }
    });
  }

  $("form#subscribe_to_newsletter").bind("ajax:error", function(e){
    console.log(e)
    var notice = e.originalEvent.detail[0].notice;
    $("body .alert-box").remove();
    $("body").append("<div class='alert-box'>" + notice + "<i class='close fas fa-times'></div>")
    $(".alert-box .close").click(function(){ $(this).parent().remove(); })
  });

  $("form#subscribe_to_newsletter").bind("ajax:success", function(e){
    var notice = e.originalEvent.detail[0].notice;
    $("body .alert-box").remove();
    $("body").append("<div class='alert-box'>" + notice + "<i class='close fas fa-times'></div>")
    $(".alert-box .close").click(function(){ $(this).parent().remove(); })
    $("form#subscribe_to_newsletter input").val("");
    $("form#subscribe_to_newsletter button").attr("disabled", "disabled")
  });

  $("#opinions-slider-mobile #show-more-opinions").click(function() {
    $("#opinions-slider-mobile .opinion-tile.mobile-hidden").each(function(i, e) {
      if (i < 3) {
        $(e).removeClass("mobile-show");
        $(e).slideDown(500, function(){
          $(e).removeClass("mobile-hidden");
        });
      }
    });

    if ($("#opinions-slider-mobile .opinion-tile.mobile-show").length == 0) {
      $(this).hide();
    }
  });

});

$(window).bind('scroll resize', function(){
  checkHeader();
});

function checkHeader() {
  if ($(window).scrollTop() > 1) {
    $("header").addClass("sticky");
  } else {
    $("header").removeClass("sticky");
  }
}

function initContactMap() {
  const infoWindow = new google.maps.InfoWindow();
  const map = new google.maps.Map(document.getElementById("contact_map"), {
    zoom: 4,
    center: { lng: 25.775816, lat: 42.012111 },
  });
  $("#contact_map").data("markers").forEach(([position, title], i) => {
    const marker = new google.maps.Marker({
      position,
      map,
      title: title,
      optimized: false
    });
    marker.addListener("click", () => {
      infoWindow.close();
      infoWindow.setContent(marker.getTitle());
      infoWindow.open(marker.getMap(), marker);
    });
  });
}
window.initContactMap = initContactMap;
